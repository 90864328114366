import { Controller } from './controller'
import { PageName } from '../services/communicationManager'

export class ControllerState<T> {
  private data!: T
  private loading: boolean
  private sideEffect?: () => void
  private update: () => void
  public readonly pageName: string
  private _isActive: boolean

  public get isActive(): boolean {
    return this._isActive
  }

  constructor(controller: Controller, sideEffect?: () => void) {
    this.update = controller.update.bind(controller)
    this.pageName = controller.pageName
    this.sideEffect = sideEffect?.bind(controller)
    this.loading = true
    this._isActive = true
  }

  activatePage(pageName: PageName) {
    if (pageName === this.pageName) {
      this._isActive = true
      return
    }
    this._isActive = false
  }

  public setLocalState(value: T) {
    this.data = value
    this.update()
  }

  public setState(value: T) {
    if (this._isActive === false) {
      return
    }

    this.data = value
    this.loading = false
    if (this.sideEffect) {
      this.sideEffect()
    }
    this.update()
  }

  public get state(): T {
    return this.data
  }

  public isEmpty(): boolean {
    return !this.data
  }

  public isLoading(): boolean {
    return this.loading
  }

  public setLoading() {
    this.loading = true
  }
}
