import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  onSnapshot,
  Query,
  QuerySnapshot,
  Unsubscribe
} from 'firebase/firestore'
import { Controller } from '~/features/controller'
import { ControllerState } from '~/features/controllerState'
import { FirestoreType } from '../composables/useFirebase'
import { PageName } from './communicationManager'

export abstract class Service<
  DataType extends FirestoreType,
  ReturnType extends DataType | DataType[] | Record<string, DataType>
> {
  private unsubscribe?: Unsubscribe
  protected subscribers: ControllerState<ReturnType>[]
  protected activePage!: PageName
  protected lastQuery: Map<PageName, Query>
  protected collection: CollectionReference
  protected collectionGroup?: Query<DataType, DocumentData>
  protected collectionConstructor: <DataType extends FirestoreType>(
    collectionName: string,
    ...pathSegments: string[]
  ) => CollectionReference<DataType, DocumentData>
  private converter: FirestoreDataConverter<DataType, DocumentData>

  constructor(collectionName: string, collectionGroupName?: string) {
    this.lastQuery = new Map()

    const { collection: collectionConstructor, collectionGroup } = useFirebase()
    this.collection = collectionConstructor(collectionName)

    if (collectionGroupName) {
      this.collectionGroup = collectionGroup(collectionName)
    }

    this.collectionConstructor = collectionConstructor
    this.subscribers = []
    const { firestoreGenericConverter } = useFirebase()
    this.converter = firestoreGenericConverter<DataType>()
  }

  public subscribe(
    controller: Controller,
    sideEffect?: () => void
  ): ControllerState<ReturnType> {
    const controllerState = new ControllerState<ReturnType>(
      controller,
      sideEffect
    )
    this.subscribers.push(controllerState)
    return controllerState
  }

  public activatePage(pageName: PageName): void {
    this.activePage = pageName

    if (this.lastQuery.has(pageName)) {
      this.listen(this.lastQuery.get(pageName)!)
    }

    for (const subscriber of this.subscribers) {
      subscriber.activatePage(pageName)
    }
  }

  protected listen(query: Query): void {
    this.lastQuery.set(this.activePage, query)

    if (this.unsubscribe) {
      this.unsubscribe()
    }

    this.unsubscribe = onSnapshot(
      query.withConverter(this.converter),
      this.updateInternalState.bind(this)
    )
  }

  protected updateInternalState(
    snapshot: QuerySnapshot<DataType, DocumentData>
  ): void {
    const newState = this.parseToReturnType(snapshot)

    for (const subscriber of this.subscribers) {
      subscriber.setState(newState)
    }
  }

  protected abstract parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>
  ): ReturnType
}

export class ArrayService<DataType extends FirestoreType> extends Service<
  DataType,
  DataType[]
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>
  ): DataType[] {
    const newState = []
    // Gather the data
    for (const doc of snapshot.docs) {
      newState.push(doc.data())
    }
    return newState
  }
}

export class MapService<DataType extends FirestoreType> extends Service<
  DataType,
  Record<string, DataType>
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>
  ): Record<string, DataType> {
    const newState: Record<string, DataType> = {}
    // Gather the data
    for (const doc of snapshot.docs) {
      newState[doc.id] = doc.data()
    }
    return newState
  }
}

export class ModelService<DataType extends FirestoreType> extends Service<
  DataType,
  DataType
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>
  ): DataType {
    return snapshot.docs[0].data()
  }
}
