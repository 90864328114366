import { query } from 'firebase/firestore'
import { ArrayService } from './service'
import { PrescriptionLine } from '~/models/PrescriptionLine'

export class PrescriptionLineService extends ArrayService<PrescriptionLine> {
  constructor() {
    super('PrescriptionLine')
  }

  public listenForAll(): void {
    const listenQuery = query(this.collection)
    this.listen(listenQuery)
  }
}
