import { documentId, query, where } from 'firebase/firestore'
import { AppUser } from '~/models'
import { MapService } from './service'

export class AppUserService extends MapService<AppUser> {
  constructor() {
    super('AppUsers')
  }

  public listenForIds(userIds: string[]): void {
    const listenQuery = query(
      this.collection,
      where(documentId(), 'in', userIds)
    )
    this.listen(listenQuery)
  }
}
