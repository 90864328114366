import { Check } from '~/models/Check'
import { ArrayService } from './service'

export class InspectionService extends ArrayService<Check> {
  constructor() {
    super('PharmacistChecks')
  }

  public listenForPharmacistChecks(): void {
    const listenQuery = this.collection
    this.listen(listenQuery)
  }
}
