import { Controller } from '~/features/controller'
import { AppUserService } from './appUserService'
import { AproDataService } from './aproDataService'
import { InspectionService } from './inspectionService'
import { MedicationAgreementService } from './medicationAgreementService'
import { PrescriptionLineService } from './prescriptionLineService'

export class CommunicationManagerContainer {
  protected appUserService?: AppUserService
  protected aproDataService?: AproDataService
  protected inspectionService?: InspectionService
  protected medAgreementService?: MedicationAgreementService
  protected prescriptionLineService?: PrescriptionLineService

  private masterControllers: Map<string, Controller> = new Map()

  public get AppUserService() {
    if (!this.appUserService) {
      this.appUserService = new AppUserService()
    }
    return this.appUserService
  }

  public get AproDataService() {
    if (!this.aproDataService) {
      this.aproDataService = new AproDataService()
    }
    return this.aproDataService
  }

  public get InspectionService() {
    if (!this.inspectionService) {
      this.inspectionService = new InspectionService()
    }
    return this.inspectionService
  }

  public get MedicationAgreementService() {
    if (!this.medAgreementService) {
      this.medAgreementService = new MedicationAgreementService()
    }
    return this.medAgreementService
  }

  public get PrescriptionLineService() {
    if (!this.prescriptionLineService) {
      this.prescriptionLineService = new PrescriptionLineService()
    }
    return this.prescriptionLineService
  }

  public addController(controller: Controller) {
    if (controller.isMaster) {
      this.masterControllers.set(controller.pageName, controller)
    }
  }

  public getMasterController<T extends Controller>(controller: Controller): T {
    const masterController = this.masterControllers.get(controller.pageName)
    if (!masterController) {
      throw new Error(`Master not found for controller`)
    }
    return masterController as T
  }

  public activatePage(pageName: PageName) {
    const services = [
      this.AppUserService,
      this.AproDataService,
      this.InspectionService,
      this.MedicationAgreementService,
      this.PrescriptionLineService
    ]

    for (const service of services) {
      service.activatePage(pageName)
    }
  }
}

export const CommunicationManager = new CommunicationManagerContainer()

export enum PageName {
  PHARMACIST_INSPECTION = 'apothekers-controle',
  LOAD_FILES = 'bestanden-inladen',
  ORDERS = 'bestellingen',
  CHECK_DELIVERY = 'controle-levering',
  INDEX = 'index',
  LOGIN = 'inloggen',
  CUSTOMER_PROFILE = 'klantprofiel',
  DELIVERY_OVERVIEW = 'leveringen-over',
  BACKORDERS = 'nazendingen',
  PARALLEL_ORDER = 'parallel-bestellen',
  PRINT = 'printen',
  PRESCRIPTION_PROCESSING = 'receptverwerking'
}
